var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{staticClass:"pd-5 main-header",attrs:{"dense":"","flat":"","color":"green darkar-4 green-gradient","height":"80px"}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.sideNav = !_vm.sideNav}}}),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},_vm._l((_vm.menus),function(item){return _c('v-btn',{key:item.title,staticClass:"flex-column-class",attrs:{"exact":"","color":"white","text":"","to":item.link}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"22","left":"","medium":""}},[_vm._v(_vm._s(item.icon))]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t(item.title))}})],1)}),1),_c('v-btn',{staticClass:"online-members ml-2",attrs:{"medium":"","elevation":"0"}},[_c('i',[_vm._v("Online Members")]),_vm._v(" "),_c('span',[_vm._v("2")])]),_c('v-spacer'),_c('v-select',{staticClass:"custom-select language-select mr-4",attrs:{"menu-props":{ top: false, offsetY: true },"item-value":function (item) { return item.lang; },"items":_vm.items,"solo":""},on:{"change":function($event){return _vm.changeLanguage(_vm.lang)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.image,"width":"34"}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"ma-2",attrs:{"width":"25","src":item.image}}),_vm._v(_vm._s(item.name)+" ")]}}]),model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({staticClass:"d-flex user-profile align-center",on:{"click":function($event){return _vm.$router.push({ name: 'Profile' })}}},'a',attrs,false),on),[_c('v-avatar',{attrs:{"rounded":"","size":"36"}},[_c('img',{attrs:{"src":require("../../assets/avatar.png")}})])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.user.name))])]),_c('v-btn',{staticClass:"mr-0",attrs:{"color":"white","icon":""},on:{"click":_vm.doLogout}},[_c('v-icon',{attrs:{"size":"25","medium":""}},[_vm._v("mdi-logout")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="fill-height">
    <Navbar />
    <CurrentBroadcasts />
    <v-main class="custom-height panel-left light-green lighten-5">
      <v-container fluid class="light-green lighten-5 pt-5 pl-5 pr-5 pb-5">
        <v-row>
          <v-col primary cols="12" sm="6">
            <Posts />
          </v-col>
          <v-col primary cols="12" sm="6">
            <v-main class="panel-right">
              <router-view></router-view>
            </v-main>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import Posts from "../components/Posts";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    Posts,
  },

  data() {
    return {};
  },
};
</script>

<template>
  <v-main class="main-sub-header">
    <v-container
      fluid
      class="d-inline-flex light-green lighten-5 pl-5 pr-5 pt-5"
    >
      <v-btn color="red accent-4" class="mr-3">
        <h3 class="mb-1 font-weight-medium">
          <v-icon size="15" class="mr-1">mdi-card-account-details</v-icon> Apple
          Auto / Carlos
        </h3>
        <div class="d-flex align-center">
          <v-icon size="15" class="mr-1">mdi-map-marker</v-icon> Angeles
          <v-icon class="mr-1 ml-2" size="15">mdi-phone-classic</v-icon>
          773-739-4559
        </div>
      </v-btn>
      <v-btn color="red accent-4" class="mr-3">
        <h3 class="mb-1 font-weight-medium">
          <v-icon size="15" class="mr-1">mdi-card-account-details</v-icon> Apple
          Auto / Carlos
        </h3>
        <div class="d-flex align-center">
          <v-icon size="15" class="mr-1">mdi-map-marker</v-icon> Angeles
          <v-icon class="mr-1 ml-2" size="15">mdi-phone-classic</v-icon>
          773-739-4559
        </div>
      </v-btn>
      <v-btn color="red accent-4" class="mr-3">
        <h3 class="mb-1 font-weight-medium">
          <v-icon size="15" class="mr-1">mdi-card-account-details</v-icon> Apple
          Auto / Carlos
        </h3>
        <div class="d-flex align-center">
          <v-icon size="15" class="mr-1">mdi-map-marker</v-icon> Angeles
          <v-icon class="mr-1 ml-2" size="15">mdi-phone-classic</v-icon>
          773-739-4559
        </div>
      </v-btn>
      <v-btn color="red accent-4" class="mr-3">
        <h3 class="mb-1 font-weight-medium">
          <v-icon size="15" class="mr-1">mdi-card-account-details</v-icon> Apple
          Auto / Carlos
        </h3>
        <div class="d-flex align-center">
          <v-icon size="15" class="mr-1">mdi-map-marker</v-icon> Angeles
          <v-icon class="mr-1 ml-2" size="15">mdi-phone-classic</v-icon>
          773-739-4559
        </div>
      </v-btn>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "CurrentBroadcasts",
  data() {
    return {};
  },
};
</script>

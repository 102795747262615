<template>
  <v-footer absolute class="pa-0" clas>
    <v-container fluid>
      <v-row>
        <v-toolbar
          color="blue-grey darken-4"
          dense
          flat
          class="pd-5 main-footer"
          height="55px"
        >
          <v-col cols="12" sm="6" md="6" class="pa-0">
            <div class="d-flex align-center justify-space-between">
              <v-btn color="white" icon>
                <v-icon size="25" medium>mdi-car</v-icon>
              </v-btn>
            </div>
          </v-col>

          <v-col class="text-right pa-0" cols="12" sm="6" md="6">
            <v-btn class="icon icon-fb" dark size="5">
              <img src="../../assets/facebook.png" />
            </v-btn>
            <v-btn class="icon icon-instagram mr-3" dark size="5">
              <img src="../../assets/instagram.png" />
            </v-btn>

            <v-btn right color="white" icon>
              <v-icon size="25" medium>mdi-cog</v-icon>
            </v-btn>
          </v-col>
        </v-toolbar>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

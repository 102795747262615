<template>
  <v-toolbar
    dense
    flat
    color="green darkar-4 green-gradient"
    class="pd-5 main-header"
    height="80px"
  >
    <v-app-bar-nav-icon
      @click.stop="sideNav = !sideNav"
      class="hidden-md-and-up"
      color="white"
    ></v-app-bar-nav-icon>
    <v-toolbar-items class="hidden-sm-and-down">
      <v-btn
        exact
        color="white"
        class="flex-column-class"
        text
        v-for="item in menus"
        :key="item.title"
        :to="item.link"
      >
        <v-icon size="22" class="mb-2" left medium>{{ item.icon }}</v-icon>
        <p v-html="$t(item.title)"></p>
      </v-btn>
    </v-toolbar-items>

    <v-btn medium class="online-members ml-2" elevation="0">
      <i>Online Members</i> <span>2</span>
    </v-btn>

    <v-spacer></v-spacer>

    <!-- user profile -->
    <v-select
      :menu-props="{ top: false, offsetY: true }"
      v-model="lang"
      :item-value="(item) => item.lang"
      :items="items"
      @change="changeLanguage(lang)"
      solo
      class="custom-select language-select mr-4"
    >
      <template v-slot:selection="{ item }">
        <img :src="item.image" width="34" class="" />
      </template>
      <template v-slot:item="{ item }">
        <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span>{{
          item.name
        }}</span>
      </template>
      <template v-slot:item="{ item }">
        <img class="ma-2" width="25" :src="item.image" />{{ item.name }}
      </template>
    </v-select>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <a
          class="d-flex user-profile align-center"
          v-bind="attrs"
          v-on="on"
          @click="$router.push({ name: 'Profile' })"
        >
          <v-avatar rounded size="36">
            <img src="../../assets/avatar.png" />
          </v-avatar>
        </a>
      </template>
      <span>{{ user.name }}</span>
    </v-tooltip>

    <v-btn class="mr-0" color="white" @click="doLogout" icon>
      <v-icon size="25" medium>mdi-logout</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";

export default {
  name: "Navbar",
  data() {
    return {
      lang: localStorage.getItem("lang"),
      items: [
        {
          name: "English",
          lang: "en",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/226-united-states.svg",
        },
        {
          name: "Spanish",
          lang: "es",
          image:
            "https://preview.keenthemes.com/metronic/vue/demo1/media/svg/flags/128-spain.svg",
        },
      ],
      sideNav: false,
      menus: [
        {
          icon: "mdi-account-multiple",
          title: "navbar.membersDirectory",
          link: "/dashboard/",
        },
        {
          icon: "mdi-account-multiple",
          title: "navbar.carHistoryReport",
          link: "/dashboard/car-history",
        },
        {
          icon: "mdi-credit-card-search",
          title: "navbar.vinSearch",
          link: "/dashboard/vin-search",
        },
        {
          icon: "mdi-cogs",
          title: "navbar.interchange",
          link: "/dashboard/interchange",
        },
        {
          icon: "mdi-text-box-search",
          title: "navbar.searchInventory",
          link: "/dashboard/serach-inventory",
        },
        {
          icon: "mdi-receipt",
          title: "navbar.invoice",
          link: "/dashboard/billing-invoice",
        },
        {
          icon: "mdi-text-box-multiple",
          title: "navbar.dmv",
          link: "/dashboard/dmv-processing",
        },
        {
          icon: "mdi-firework",
          title: "navbar.auction",
          link: "/dashboard/car-auctions",
        },
        {
          icon: "mdi-cart",
          title: "navbar.trade",
          link: "/dashboard/trade-car-parts",
        },
        {
          icon: "mdi-cart",
          title: "navbar.sellingEbay",
          link: "/dashboard/selling-ebay",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
      userServices: "user/getUserServices",
    }),
  },
  methods: {
    ...mapActions({
      logout: "user/logout",
    }),
    async doLogout() {
      try {
        await this.logout();
        this.$router.push("/");
      } catch ({ message }) {
        console.log(message);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
    },
  },
};
</script>

<template>
  <v-main class="panel-left">
    <ApiErrorMessage :message="apiErrorMessage" />
    <!-- message -->
    <div class="messsge-search d-flex align-center pl-4 pr-4 pt-3 pb-3 white">
      <v-form class="mr-3">
        <v-card class="pa-0 d-flex align-center" flat>
          <v-text-field
            v-model="message"
            class="mr-3"
            elevation="0"
            height="60"
            dense
            :label="$t('posts.typeYourMessageHere')"
            solo
          ></v-text-field>
          <v-btn
            medium
            @click="post"
            light
            min-height="60"
            class="green darken-4 green-gradient white--text"
            elevation="0"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-card>
      </v-form>

      <div class="btn-msg ml-auto">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('posts.oldPostedMessages')"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            color="green darken-4"
            type="month"
            v-model="date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </div>
    </div>

    <!-- search -->
    <div
      class="word-search d-flex align-center pl-4 pr-4 pt-3 pb-3 white bt-top"
    >
      <v-form class="mr-3">
        <v-card class="pa-0 d-flex word-search" flat>
          <v-text-field
            v-model="search"
            elevation="0"
            height="40"
            dense
            :label="$t('posts.searchForWords')"
            solo
            append-icon="mdi-magnify"
          ></v-text-field>
        </v-card>
      </v-form>

      <div class="btn-msg ml-auto d-flex">
        <v-btn elevation="0" class="mr-3" green>
          <v-icon class="mr-2">mdi-forum</v-icon>
          {{ $t("posts.chatWithDismentlars") }}
        </v-btn>
        <v-btn
          elevation="0"
          green
          @click="$router.push('/dashboard/message-center')"
        >
          <v-icon class="mr-2">mdi-email</v-icon>
          {{ $t("posts.viewMessage") }}
        </v-btn>
      </div>
    </div>

    <!-- tables -->
    <div class="custom-card">
      <div class="custom-box">
        <v-data-table
          class="color-green disabled-footer"
          height="calc(100vh - 455px)"
          :headers="headers"
          :items="posts"
          :disable-sort="true"
          fixed-header
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :search="search"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          hide-default-footer
        >
          <template
            v-slot:item.data-table-expand="{ expand, isExpanded, item }"
          >
            <v-btn
              :disabled="item.is_replied == true"
              small
              color="green darkan-4 green-gradient white--text"
              class="text-capitalize"
              @click="expand(!isExpanded)"
            >
              {{ $t("posts.replyOnCall") }}
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" elevation="0">
              <v-form class="pa-4 form-custom">
                <v-card class="pa-0 d-flex align-center" flat>
                  <v-text-field
                    v-model="reply"
                    class="mr-3"
                    height="50"
                    elevation="0"
                    dense
                    :label="$t('posts.enterText')"
                    solo
                  ></v-text-field>
                  <v-btn
                    height="50"
                    medium
                    @click="replyOnPost(item)"
                    dense
                    class="green darken-4 green-gradient white--text text-capitalize"
                    elevation="0"
                  >
                    {{ $t("posts.send") }}
                  </v-btn>
                </v-card>
              </v-form>
            </td>
          </template>
        </v-data-table>
        <div class="text-center">
            <!-- <v-pagination v-model="pageNo" :length="totalPages"></v-pagination> -->
        </div>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },
  data() {
    return {
      message: null,
      dialog: false,
      reply: null,
      menu2: false,
      date: new Date().toISOString().substr(0, 10),
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 8,
      apiErrorMessage: null,
      singleExpand: true,
      expanded: [],
      pageNo: 1,
      totalPages: 3
    };
  },

  computed: {
    ...mapGetters({
      posts: "posts/getPosts",
      postThreads: "postThread/getPostThreads",
    }),
    headers() {
      return [
        {
          text: this.$t("posts.date"),
          align: "start",
          sortable: false,
          value: "created_at",
        },
        { text: this.$t("posts.postMessage"), value: "message" },
        { text: this.$t("posts.dismenter"), value: "owner.name" },
        { text: "", value: "data-table-expand" },
      ];
    },
  },

  async mounted() {
    this.$store.commit("setLoader", true);
    try {
      await this.getPosts();
    } catch ({ message }) {
      this.apiErrorMessage = message;
    } finally {
      this.$store.commit("setLoader", false);
    }
  },

  methods: {
    ...mapActions({
      getPostThreads: "postThread/getPostThreads",
      getPosts: "posts/getPosts",
      createPost: "posts/createPost",
      createMessages: "messages/createMessages",
    }),

    async post() {
      this.$store.commit("setLoader", true);
      try {
        await this.createPost({ message: this.message });
        this.message = null;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },

    async replyOnPost(post) {
      this.$store.commit("setLoader", true);
      try {
        await this.createMessages({
          post_id: post.id,
          recipient_id: post.owner.id,
          body: this.reply,
        });
        await this.getPosts();
        await this.getPostThreads();
        this.reply = null;
        this.expanded = [];
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.$store.commit("setLoader", false);
      }
    },
  },
};
</script>
